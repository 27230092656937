<template>
  <el-dialog
      :title="type === 1 ? '开柜门' : type === 2 ? '取床' : type === 3 ? '关柜门' : type === 4 ? '还床' : '副货道出货'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      width="360px">
    <el-form label-position="left" class="common-form" label-width="100px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="货道id：" prop="mainCay">
        <el-input class="small-input" v-model="form.mainCay" placeholder="请输入货道id"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：" prop="orderId" v-if="type !== 5">
        <el-input class="small-input" v-model="form.orderId" placeholder="请输入订单编号"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="type" v-if="type === 3 || type === 1">
        <el-select class="small-input" v-model="form.type" placeholder="状态">
          <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="closeModal">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "GatewayNumber",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: Number,
      default: 1
    },
    deviceId:{
      type:String,
      default:""
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form.gatewayNumber = "";
        this.form.orderId = "";
        this.form.type = 0;
      }
      if(!val){
        this.$refs.form.resetFields();
      }
    }
  },
  data() {
    return {
      form: {
        mainCay: '',
        orderId:'',
        type:0
      },
      typeList:[
        {
          value:0,
          label:'租床'
        },
        {
          value:1,
          label:'还床'
        }
      ],
      rules: {
        mainCay: [
          {required: true, message: '请输入货道id', trigger: 'blur'},
        ],
        orderId:[
          {required: true, message: '请输入订单编号', trigger: 'blur'},
        ],
        type:[
          {required: true, message: '请输入请选择状态', trigger: 'change'},
        ]
      },
      loading: false
    }
  },
  methods: {
    handleClose() {
      this.closeModal()
    },
    closeModal() {
      this.$emit("close", false);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          this.loading = true;
          let formData = {
            deviceId: this.deviceId,
            mainCay:this.form.mainCay
          }
          switch (this.type){
            case 1:{
              formData.orderId = this.form.orderId;
              formData.type = this.form.type;
              this.$axios.post("/sys-api/device-api/open",formData,(res) => {
                this.loading = false;
                if(res.code === '100'){
                  this.$message.success("开柜门成功");
                  this.closeModal();
                }
              })
              break;
            }
            case 2:{
              formData.orderId = this.form.orderId;
              this.$axios.post("/sys-api/device-api/fetch",formData,(res) => {
                this.loading = false;
                if(res.code === '100'){
                  this.$message.success("取床成功");
                  this.closeModal();
                }
              })
              break;
            }
            case 3:{
              formData.orderId = this.form.orderId;
              formData.type = this.form.type;
              this.$axios.post("/sys-api/device-api/close",formData,(res) => {
                this.loading = false;
                if(res.code === '100'){
                  this.$message.success("关柜门成功");
                  this.closeModal();
                }
              })
              break;
            }
            case 4:{
              formData.orderId = this.form.orderId;
              this.$axios.post("/sys-api/device-api/repay",formData,(res) => {
                this.loading = false;
                if(res.code === '100'){
                  this.$message.success("还床成功");
                  this.closeModal();
                }
              })
              break;
            }
            case 5:{
              this.$axios.post("/sys-api/device-api/auxiliary",formData,(res) => {
                this.loading = false;
                if(res.code === '100'){
                  this.$message.success("副货道出货成功");
                  this.closeModal();
                }
              })
              break;
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
