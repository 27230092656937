<template>
  <div style="position: relative">
    <!--    <div class="block-title">-->
    <!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>设备详情</div>-->
    <!--    </div>-->
    <!--    <div class="btn-container" v-if="activeName === '3'">-->
    <!--      <el-button size="small" type="primary">货道详情</el-button>-->
    <!--    </div>-->
    <el-tabs v-model="activeName" type="card" @tab-click="changeTab">
      <el-tab-pane label="基本信息" name="1">
        <el-form label-position="left" class="left_10 common-form show-form" label-width="100px" :model="baseForm">
          <el-form-item label="id：" prop="id">
            {{ baseForm.id || '-' }}
          </el-form-item>
          <el-form-item label="imei：" prop="imei">
            {{ baseForm.imei || '-' }}
          </el-form-item>
          <el-form-item label="APK版本号：" prop="apkVersion">
            {{ baseForm.apkVer || '-' }}
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            {{ baseForm.deviceType === 0 ? '租赁柜-彩屏-30门' : '-' }}
          </el-form-item>
          <el-form-item label="是否在线：" prop="isOnline">
            {{ baseForm.onlineStatus === 1 ? '在线' : baseForm.onlineStatus === 0 ? '离线' : '-' }}
          </el-form-item>
          <el-form-item label="是否可用：" prop="isUsed">
            {{ baseForm.deviceStatus === 1 ? '可用' : baseForm.deviceStatus === 0 ? '禁用' : '-' }}
          </el-form-item>
          <el-form-item label="心跳时间：" prop="heartTime">
            <span class="value">{{ baseForm.createTime }}</span>
            <el-button size="small" class="left_30" @click="goHeartRecord">心跳记录</el-button>
          </el-form-item>
        </el-form>
        <div class="btn-list left_10">
          <el-button type="primary" @click="changeDeviceStatus" :loading="forbiddenLoading" style="padding: 12px 30px">
            {{ baseForm.deviceStatus === 0 ? '设为可用' : '设为不可用' }}</el-button>
          <el-button type="primary" style="padding: 12px 30px" @click="rebootDevice">重启设备</el-button>
          <el-button type="primary" style="padding: 12px 30px" :loading="deleteLoading" @click="deleteDevice">删除设备</el-button>
          <el-button type="primary" style="padding: 12px 30px" @click="handleCloseGatewayNumber(true,1)">开柜门</el-button>
          <el-button type="primary" style="padding: 12px 30px" @click="handleCloseGatewayNumber(true,2)">取床</el-button>
          <el-button type="primary" style="padding: 12px 30px" @click="handleCloseGatewayNumber(true,3)">关柜门</el-button>
          <el-button type="primary" style="padding: 12px 30px" @click="handleCloseGatewayNumber(true,4)">还床</el-button>
          <!--<el-button type="primary" style="padding: 12px 30px" @click="resetDevice()" :loading="resetLoading">重置</el-button>-->
          <el-button type="primary" style="padding: 12px 30px" @click="upgradeApk()" :loading="upgradeLoading">升级apk</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="场地信息" name="2">
        <el-form label-position="left" class="left_10 common-form" ref="positionForm" :rules="positionRules"
                 label-width="100px" :model="positionForm">
          <el-form-item class="not_required" label="设备名称：" prop="name">
            {{ positionForm.name }}
          </el-form-item>
          <el-form-item label="所在单位：" prop="departmentId">
            <el-select class="small-input" @change="handleChangeDepartment" v-model="positionForm.departmentId"
                       placeholder="请选择单位">
              <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在楼宇：" prop="building">
            <el-select clearable class="small-input" v-model="positionForm.building" placeholder="请选择楼宇" @change="changeBuilding">
              <el-option
                  v-for="item in buildingList"
                  :key="item.building"
                  :label="item.building"
                  :value="item.building">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在楼层：" prop="floor">
            <el-select clearable class="small-input" v-model="positionForm.floor" placeholder="请选择楼层" @change="changeFloor">
              <el-option
                  v-for="item in floorList"
                  :key="item.floor"
                  :label="item.floor"
                  :value="item.floor">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在位置：" prop="place">
            <el-select clearable class="small-input" v-model="positionForm.place" placeholder="请选择位置" @change="changePlace">
              <el-option
                  v-for="item in placeList"
                  :key="item.place"
                  :label="item.place"
                  :value="item.place">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="btn-list submit-btn-container">
          <el-button type="primary" class="submit-btn" :loading="updatePositionLoading" @click="updatePosition">确 定
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="主货道信息" name="3">
        <div class="total-info">
          <div class="total-item blue-item">
            <span>总数：</span>
            <span>{{ mainStatisticsInfo.amount || 0 }}</span>
          </div>
          <div class="total-item green-item">
            <span>可出租：</span>
            <span>{{ mainStatisticsInfo.mayRentOut || 0 }}</span>
          </div>
          <div class="total-item orange-item">
            <span>出租中：</span>
            <span>{{ mainStatisticsInfo.rentOut || 0 }}</span>
          </div>

          <div class="total-item gray-item">
            <span>当前异常：</span>
            <span>{{ mainStatisticsInfo.anomalyNumber || 0 }}</span>
          </div>
        </div>
        <el-form label-position="left" class="left_10 common-form show-form" label-width="60px"
                 style="margin-top: 10px">
          <el-form-item label="商品：" prop="goodName">
            <span class="value">{{
                mainGoodsTableData && mainGoodsTableData.length > 0 ? mainGoodsTableData[0].name : '-'
              }}</span>
          </el-form-item>
        </el-form>
        <div class="table-title">货道信息</div>
        <div class="table-container-main">
          <el-table
              class="table"
              height="100%"
              v-loading="mainGoodsTableLoading"
              :data="mainGoodsTableData">
            <el-table-column
                label="货道id"
                fixed="left"
                :formatter="commonFormatter"
                align="center"
                header-align="center"
                prop="id">
            </el-table-column>
            <el-table-column
                label="货道编号"
                fixed="left"
                :formatter="commonFormatter"
                align="center"
                header-align="center"
                prop="wayNo">
            </el-table-column>
            <el-table-column
                label="货道状态"
                align="center"
                header-align="center"
                prop="status">
              <template slot-scope="{row}">
                {{ row.status === 0 ? '可租赁' : row.status === 1 ? '已分配' : row.status === 2 ? '租赁中' : row.status === 3 ? '归还中' : row.status === 4 ? '消毒中' : row.status === 5 ? '冷却中' : row.status === 6 ? '不可用' : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                label="商品名称"
                prop="name"
                align="center"
                header-align="center"
            >
              <template slot-scope="{row}">
                {{row.name === 0 ? '陪护床' : '-'}}
              </template>
            </el-table-column>
            <el-table-column
                label="商品RFID编号"
                prop="rfId"
                width="150"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="货道内是否有商品"
                prop="hasGoods"
                width="150"
                align="center"
                header-align="center"
            >
              <template slot-scope="{row}">
                {{ row.hasGoods === 1 ? '有床' : row.hasGoods === 0 ? '无床' : row.hasGoods===2 ?'RFID异常':'-' }}
              </template>
            </el-table-column>
            <el-table-column
                label="锁状态"
                prop="doorStatus"
                align="center"
                header-align="center"
            >
              <template slot-scope="{row}">
                {{row.doorStatus === 1 ? '锁开' : row.doorStatus === 0 ? '锁关' : row.doorStatus===2 ?'异常':'-' }}
              </template>
            </el-table-column>
            <el-table-column
                label="是否可用"
                prop="status"
                align="center"
                header-align="center"
            >
              <template slot-scope="{row}">
                {{ row.status === 1 ? '已分配' : row.status === 0 ? '可租赁' : row.status === 2 ? '租赁中' : row.status === 3 ? '归还中' : row.status === 4 ? '消毒中' : row.status === 5 ? '冷却中' : row.status === 6 ? '不可用' : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                label="当前异常"
                align="center"
                header-align="center"
                prop="status">
              <template slot-scope="{row}">
                {{ row.doorStatus === 2 ? '锁异常' : row.hasGoods === 2 ? '床异常' : '无' }}
              </template>
            </el-table-column>
            <el-table-column
                label="当前订单"
                width="150"
                align="center"
                header-align="center"
                prop="orderNo"
            >
              <template slot-scope="scope">
                <span class="link_word" @click="goOrderDetail(scope.row.orderNo)">{{ scope.row.orderNo || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="订单状态"
                prop="orderStatus"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
              <template slot-scope="{row}">
                {{ row.orderStatus === 0 ? '下单待支付' : row.orderStatus === 1 ? '已支付待取货' : row.orderStatus === 2 ? '租赁中' : row.orderStatus === 3 ? '已归还待确认' : row.orderStatus === 4 ? '已确认待结算' : row.orderStatus === 5 ? '归还结算完成' : row.orderStatus === 6 ? '订单关闭' : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                align="center"
                header-align="center"
                label="操作">
              <template slot-scope="{row}">
                <el-button type="text" @click="getMainGoodsTable(row)">刷新状态</el-button>
                <el-button type="text" @click="openDoor(row)">开门</el-button>
                <el-button type="text" @click="changeWayStatus(row)">{{row.status === 6 ? '启用' : '设为不可用'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="主货道商品" name="4">
        <div class="table-container-main-goods">
          <el-table
              class="table"
              height="100%"
              v-loading="mainGoodsRfIdTableLoading"
              :data="mainGoodsRfIdTableData">
            <!--            <el-table-column-->
            <!--                label="设备id"-->
            <!--                prop="id"-->
            <!--                align="center"-->
            <!--                header-align="center"-->
            <!--                :formatter="commonFormatter"-->
            <!--            >-->
            <!--            </el-table-column>-->
            <!--            <el-table-column-->
            <!--                label="主货道id"-->
            <!--                align="center"-->
            <!--                header-align="center"-->
            <!--                prop="mainCargoId"-->
            <!--                :formatter="commonFormatter"-->
            <!--            >-->
            <!--            </el-table-column>-->
            <el-table-column
                label="主货道编号"
                align="center"
                header-align="center"
                prop="mainWayNo"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <!--<el-table-column
                label="商品名"
                align="center"
                header-align="center"
                prop="goodsName"
            >
              <template slot-scope="{row}">
                {{row.goodsName === 0 ? '陪护床' : '-'}}
              </template>
            </el-table-column>-->
            <el-table-column
                label="商品RFID"
                align="center"
                header-align="center"
                prop="rfid"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                align="center"
                header-align="center"
                label="操作">
              <template slot-scope="{row}">
                <el-button type="text" @click="changeRfId(row)">修改RFID</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="副货道信息" name="5">
        <el-form label-position="left" class="left_10 common-form show-form" label-width="60px" :model="otherGoodsForm">
          <el-form-item label="商品：" prop="goodName">
            <span class="value">床单</span>
          </el-form-item>
        </el-form>
        <template>
          <el-tabs :tab-position="tabPosition" v-model="activeName1" @tab-click="changeTab1">
            <el-tab-pane label="货道信息" name="51">
              <div class="table-title">货道信息</div>
              <!--              <div class="table-container-main">-->
              <el-table
                  class="table"
                  height="100%"
                  v-loading="auxiliaryTableLoading"
                  :data="auxiliaryTableData">
                <el-table-column
                    label="货道id"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="id">
                </el-table-column>
                <el-table-column
                    label="货道编号"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="wayNo">
                </el-table-column>
                <el-table-column
                    label="货道状态"
                    align="center"
                    header-align="center"
                    prop="status">
                  <template slot-scope="{row}">
                    {{ row.status === 1 ? '可用' : '禁用' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="库存"
                    prop="amount"
                    width="150"
                    align="center"
                    header-align="center"
                    :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                    width="600"
                    align="center"
                    header-align="center"
                    label="操作">
                  <template slot-scope="{row}">
                    <el-button type="text" @click="Replenishment(row)">补货</el-button>
                    <el-button type="text" @click="TurnsMotor(row)">转动</el-button>
                    <el-button type="text" @click="changeAnStatus(row)">{{row.status === 0 ? '启用' : '设为不可用'}}</el-button>
                    <el-button type="text" v-show="row.wayNo != null" @click="Oneclickreplenishment(row)">一键补货</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!--              </div>-->
            </el-tab-pane>
            <el-tab-pane label="补货记录" name="52">
              <div class="table-title">补货记录</div>
              <el-table
                  class="table"
                  height="100%"
                  v-loading="auxiliaryTableLoading"
                  :data="replenishmentrecord">
                <el-table-column
                    label="电机编号"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="wayNo">
                </el-table-column>
                <el-table-column
                    label="补货后数量"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="amount">
                </el-table-column>
                <el-table-column
                    label="补货时间"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="updateTime">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="失败记录" name="53">
              <el-table
                  class="table"
                  height="100%"
                  v-loading="auxiliaryTableLoading"
                  :data="FailureRecord">
                <el-table-column
                    label="设备编号"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="deviceId">
                </el-table-column>
                <el-table-column
                    label="电机编号"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="wayNo">
                </el-table-column>
                <el-table-column
                    label="失败时间"
                    fixed="left"
                    :formatter="commonFormatter"
                    align="center"
                    header-align="center"
                    prop="failureTime">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </template>


        <!--        <div class="table-title">补货记录</div>
                <div class="table-container-other">
                  <el-table
                      class="table"
                      height="100%"
                      v-loading="otherGoodsTableLoading"
                      :data="otherGoodsForm.replenishmentRecordBean">
                    <el-table-column
                        label="补货时间"
                        prop="replenishTime"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                    >
                    </el-table-column>
                    <el-table-column
                        label="补货用户"
                        align="center"
                        header-align="center"
                        prop="replenishUser"
                        :formatter="commonFormatter"
                    >
                    </el-table-column>
                    <el-table-column
                        label="补货货道"
                        prop="wayNo"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                    >
                    </el-table-column>
                    <el-table-column
                        label="补货备注"
                        align="center"
                        header-align="center"
                        prop="remark"
                        :formatter="commonFormatter"
                    >
                    </el-table-column>
                  </el-table>
                </div>-->
      </el-tab-pane>
      <el-tab-pane label="异常信息" name="6">
        <div class="table-container-error">
          <el-table
              class="table"
              height="100%"
              v-loading="errorTableLoading"
              :data="errorTableData">
            <el-table-column
                label="上报时间"
                prop="submitTime"
                width="200"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="异常类型"
                prop="type"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="设备id"
                prop="id"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="设备imei"
                prop="imei"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="货道编号"
                prop="mainWayNo"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="库存"
                prop="inventory"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="所在单位"
                prop="department"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="所在位置"
                prop="position"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="运营联系人"
                prop="contacts"
                align="center"
                header-align="center"
                :formatter="commonFormatter"
            >
            </el-table-column>
            <el-table-column
                label="是否已处理"
                prop="isDeal"
                align="center"
                header-align="center"
            >
              <template slot-scope="scope">
                <el-switch style="margin-left: 10px" :disabled="true"
                           v-model="scope.row.isDeal">
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--<UpdateRfId :dialogVisible="updateRfIdDialogVisible" @close="handleCloseUpdateRfId"
                :editRfId="editRfId"></UpdateRfId>-->
    <GatewayNumber :dialogVisible="gatewayNumberDialogVisible" @close="handleCloseGatewayNumber" :type="type" :deviceId="deviceId"></GatewayNumber>

  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {resetMenuList, setMenuList} from "@/assets/js/common";
/*import UpdateRfId from "@/views/device/UpdateRfId";*/
import GatewayNumber from "@/views/device/GatewayNumber";

export default {
  name: "DeviceDetail",
  mixins: [tableUse],
  components: {
    GatewayNumber
  },
  computed: {
    deviceId() {
      return this.$route.query.id
    }
  },
  watch:{
    'positionForm.building'(){
      this.getPositionId();
    },
    'positionForm.floor'(){
      this.getPositionId();
    },
    'positionForm.place'(){
      this.getPositionId();
    }
  },
  data() {
    return {
      activeName1:'51',
      tabPosition:'right',
      auxiliaryTableData:[],
      replenishmentrecord:[],
      FailureRecord:[],
      updateRfIdDialogVisible: false,
      gatewayNumberDialogVisible:false,
      type:1,
      activeName: '1',
      forbiddenLoading:false,
      baseForm: {},
      positionForm: {
        departmentId: '',
        positionId: '',
        building: '',
        floor:'',
        place:'',
      },
      positionRules: {
        departmentId: [
          {required: true, message: '请选择单位', trigger: 'change'},
        ],
        building: [
          {required: true, message: '请选择楼宇', trigger: 'change'},
        ],
        floor:[
          {required: true, message: '请选择楼层', trigger: 'change'},
        ],
        place:[
          {required: true, message: '请选择位置', trigger: 'change'},
        ],
      },
      mainStatisticsInfo: {},
      otherGoodsForm: {
        replenishmentRecordBean: []
      },
      departmentList: [],
      positionList: [],
      buildingList:[],
      floorList:[],
      placeList:[],
      mainGoodsTableData: [],
      mainGoodsRfIdTableData: [],
      errorTableData: [],
      deleteLoading: false,
      updatePositionLoading: false,
      mainGoodsTableLoading: false,
      mainGoodsRfIdTableLoading: false,
      otherGoodsTableLoading: false,
      errorTableLoading: false,
      auxiliaryTableLoading: false,
      editRfId: null,
      resetLoading:false,
      upgradeLoading:false,
      dialogFormVisible:false
    }
  },
  methods: {
    Oneclickreplenishment(row){
      this.$axios.post("/sys-api/device-api/Replenishment", {id: row.id,amount:12,deviceId:this.deviceId,wayNo:row.wayNo}, (res) => {
        if (res.code === '100') {
          this.getOtherGoodsInfo()
          this.$message({
            type: 'success',
            message: '补货成功'
          });
        }
      })
    },
    rebootDevice(){
      this.$axios.get("/sys-api/device-api/rebootDevice", {deviceId: this.deviceId}, (res) => {
        if (res.code === '100') {
          this.getOtherGoodsInfo()
          this.$message({
            type: 'success',
            message: '重启成功'
          });
        }
      })
    },
    TurnsMotor(row){
      if (row.status===0){
        this.$message.error("货道不可用")
      }
      else {
        this.$axios.get("/sys-api/device-api/TurnsMotor", {deviceId: this.deviceId,wayNo:row.wayNo}, (res) => {
          if (res.code === '100') {
            this.getOtherGoodsInfo()
            this.$message({
              type: 'success',
              message: '转电机成功'
            });
          }
        })
      }
    },
    Replenishment (row){
      this.$prompt('请输入补货数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$axios.post("/sys-api/device-api/Replenishment", {id: row.id,amount:Number(row.amount)+Number(value),deviceId:this.deviceId,wayNo:row.wayNo}, (res) => {
          if (res.code === '100') {
            this.getOtherGoodsInfo()
            this.$message({
              type: 'success',
              message: '补货成功'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });

    },
    getReplenishmentRecord(){
      this.$axios.get("/sys-api/device-api/getReplenishmentRecord", {deviceId:this.deviceId}, (res) => {
        if (res.code === '100') {
          this.replenishmentrecord = res.data
        }
      })
    },
    getFailureRecord(){
      this.$axios.get("/sys-api/device-api/getFailureRecord", {deviceId:this.deviceId}, (res) => {
        if (res.code === '100') {
          this.FailureRecord = res.data
        }
      })
    },
    changeAnStatus(row){
      this.$axios.get("/sys-api/device-api/prohibitAuxiliaryCargo", {deviceId:this.deviceId,wayNo:row.wayNo}, (res) => {
        if (res.code === '100') {
          if (row.status === 0){
            this.$message.success("启用成功");
          }
          else {
            this.$message.success("禁用成功");
          }
          this.getOtherGoodsInfo()
        }
      })
    },
    goBack() {
      this.$router.back();
    },
    goOrderDetail(orderNo) {
      let menuList = [{
        name: "订单管理"
      }, {
        name: "订单列表",
        path: "/order"
      }, {
        name: "订单详情",
        path: "/orderDetail"
      }]
      resetMenuList(menuList)
      this.$router.push({
        name: 'OrderDetail',
        query:{
          orderNo
        }
      })
    },
    changeTab() {
      switch (this.activeName) {
        case "1": {
          this.getDeviceBaseInfo();
          break;
        }
        case "2": {
          this.getPositionInfo();
          this.getDepartmentList();
          break;
        }
        case "3": {
          this.getMainStatisticsInfo();
          this.getMainGoodsTableData();
          break;
        }
        case "4": {
          this.getMainGoodsRfIdTableData();
          break;
        }
        case "5": {
          this.getOtherGoodsInfo();
          break;
        }
        case "6": {
          this.getErrorTableData();
          break;
        }
      }
    },
    changeTab1(){
      switch (this.activeName1) {
        case "51": {
          this.getOtherGoodsInfo();
          break;
        }
        case "52": {
          this.getReplenishmentRecord();
          break;
        }
        case "53": {
          this.getFailureRecord();
          break;
        }
      }
    },
    getDeviceBaseInfo() {
      this.$axios.get("/sys-api/device-api/getDeviceById", {id: this.deviceId}, (res) => {
        if (res.code === '100') {
          this.baseForm = res.data || {};
        }
      })
    },
    getPositionInfo() {
      this.$axios.get("/sys-api/device-api/getSite", {id: this.deviceId}, (res) => {
        if (res.code === '100') {
          this.positionForm = res.data || {};
          if (this.positionForm.departmentId) this.getPositionList();
        }
      })
    },
    getDepartmentList() {
      this.$axios.get("/sys-api/site-api/getDepartment", {}, (res) => {
        if (res.code === '100') {
          this.departmentList = res.data;
        }
      })
    },
    handleChangeDepartment() {
      this.positionForm.positionId = '';
      this.positionForm.building = "";
      this.positionForm.floor = "";
      this.positionForm.place = "";
      this.getPositionList();
    },
    getPositionId(){
      if(this.positionForm.building && this.positionForm.floor && this.positionForm.place){
        let selected = this.positionList.filter((item) => {return item.building === this.positionForm.building && item.floor === this.positionForm.floor && item.place === this.positionForm.place})
        if(selected.length > 0) this.positionForm.positionId = selected[0].id;
      }
    },
    changeBuilding(){
      this.positionForm.floor = "";
      this.positionForm.place = "";
      this.getFloorList(this.positionList);
      //this.getPlaceList(this.positionList);
    },
    changeFloor(){
      this.positionForm.place = "";
      //this.getBuildingList(this.positionList);
      this.getPlaceList(this.positionList);
    },
    changePlace(){
      /*console.log(this.buildingList);
      console.log(this.floorList);
      console.log(this.placeList);*/
      //this.getBuildingList(this.positionList);
      //this.getFloorList(this.positionList);
    },
    getBuildingList(arr){
      this.buildingList = [];
      // if(this.positionForm.floor) arr = arr.filter((item) => {return item.floor === this.positionForm.floor});
      // if(this.positionForm.place) arr = arr.filter((item) => {return item.place === this.positionForm.place});
      arr.forEach((item) => {
        if(this.buildingList.every((exist) => {return item.building !== exist.building})){
          this.buildingList.push(item);
        }
      })
    },
    getFloorList(arr){
      this.floorList = [];
      if(this.positionForm.building) arr = arr.filter((item) => {return item.building === this.positionForm.building});
      // if(this.positionForm.place) arr = arr.filter((item) => {return item.place === this.positionForm.place});
      arr.forEach((item) => {
        if(this.floorList.every((exist) => {return item.floor !== exist.floor})){
          this.floorList.push(item);
        }
      })
    },
    getPlaceList(arr){
      this.placeList = [];
      if(this.positionForm.building) arr = arr.filter((item) => {return item.building === this.positionForm.building});
      if(this.positionForm.floor) arr = arr.filter((item) => {return item.floor === this.positionForm.floor});
      arr.forEach((item) => {
        if(this.placeList.every((exist) => {return item.place !== exist.place})){
          this.placeList.push(item);
        }
      })
    },
    getPositionList() {
      this.$axios.get("/sys-api/site-api/getPosition", {
        departmentId: this.positionForm.departmentId
      }, (res) => {
        if (res.code === '100') {
          this.getBuildingList(res.data || []);
          this.getFloorList(res.data || []);
          this.getPlaceList(res.data || []);
          this.positionList = res.data || [];
          /*console.log(this.buildingList);
          console.log(this.floorList);
          console.log(this.placeList);*/
        }
      })
    },
    getMainStatisticsInfo() {
      this.$axios.get("/sys-api/device-api/getMainCargoWaySum", {id: this.deviceId}, (res) => {
        if (res.code === '100') {
          this.mainStatisticsInfo = res.data;
        }
      })
    },
    getMainGoodsTableData() {
      this.mainGoodsTableLoading = true;
      this.$axios.get("/sys-api/device-api/getMainCargoWay", {id: this.deviceId}, (res) => {
        this.mainGoodsTableLoading = false;
        if (res.code === '100') {
          this.mainGoodsTableData = res.data
        }
      })
    },
    getMainGoodsTable(row) {
      this.mainGoodsTableLoading = true;
      this.$axios.get(`/sys-api/device-api/examinationBed`, {deviceId:this.deviceId,wayNo:row.wayNo}, (res) => {
        this.mainGoodsTableLoading = false;
        if (res.code === '100') {
          this.$axios.get("/sys-api/device-api/getMainCargoWay", {id: this.deviceId}, (res) => {
            this.mainGoodsTableLoading = false;
            if (res.code === '100') {
              this.mainGoodsTableData = res.data;
              this.$message.success("刷新成功")
            }
          })
        }
      })

    },
    getMainGoodsRfIdTableData() {
      this.mainGoodsRfIdTableLoading = true;
      this.$axios.get("/sys-api/device-api/getDeviceGoodsList", {id: this.deviceId}, (res) => {
        this.mainGoodsRfIdTableLoading = false;
        if (res.code === '100') {
          this.mainGoodsRfIdTableData = res.data;
        }
      })
    },
    getOtherGoodsInfo() {
      this.otherGoodsTableLoading = true;
      this.$axios.get("/sys-api/device-api/getAllAuxiliaryCargo", {deviceId: this.deviceId}, (res) => {
        this.otherGoodsTableLoading = false;
        if (res.code === '100') {
          if (!res.data.auxiliaryTableData) res.data.auxiliaryTableData = [];
          this.auxiliaryTableData = res.data;
        }
      })
    },
    getErrorTableData() {
      this.errorTableLoading = true;
      this.$axios.get("/sys-api/device-api/getAbnormal", {id: this.deviceId}, (res) => {
        this.errorTableLoading = false;
        if (res.code === '100') {
          this.errorTableData = res.data;
        }
      })
    },
    deleteDevice() {
      this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteLoading = true;
        this.$axios.postForm("/sys-api/device-api/deleteDevice", {ids: this.deviceId}, (res) => {
          this.deleteLoading = false;
          if (res.code === '100') {
            this.$message.success("设备删除成功");
            this.$router.push({name: 'DeviceList'});
          }
        })
      });
    },
    updatePosition() {
      this.updatePositionLoading = true;
      this.$axios.post("/sys-api/device-api/updateDeviceSite", {
        id: this.deviceId,
        departmentId: this.positionForm.departmentId,
        positionId: this.positionForm.positionId
      }, (res) => {
        this.updatePositionLoading = false;
        if (res.code === '100') {
          this.$message.success("更新场地信息成功");
        }
      })
    },
    changeRfId(row) {
      this.$prompt('请输入RFID', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$axios.post("/sys-api/device-api/updateRFID", {id:row.id,wayNo: row.mainWayNo,rfid:value,deviceId:this.deviceId,oldRfid:row.rfid,updateId:JSON.parse(window.localStorage.getItem('userInfo')).id}, (res) => {
          if (res.code === '100') {
            this.getMainGoodsRfIdTableData();
            this.$message({
              type: 'success',
              message: '更新成功'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    openDoor(row){
      this.$axios.post("/sys-api/device-api/mqttOpenDoor",{deviceId:row.deviceId,wayNo:row.wayNo},(res) => {
        if(res.code === '100'){
          this.$message.success('已通知');
        }
      })
    },
    changeWayStatus(row){
      if(row.status === 6){
        //货道设为可用
        this.$axios.post('/sys-api/device-api/mainCargoEnable',{id:row.id},(res) => {
          if(res.code === '100'){
            this.$message.success('启用成功');
            this.getMainGoodsTableData();
            this.getMainStatisticsInfo();
          }
        })
      }else{
        //货道设为不可用
        this.$axios.post('/sys-api/device-api/mainCargoForbidden',{id:row.id},(res) => {
          if(res.code === '100'){
            this.$message.success('禁用成功');
            this.getMainGoodsTableData();
            this.getMainStatisticsInfo();
          }
        })
      }
    },
    handleCloseUpdateRfId(visible, isRe) {
      this.updateRfIdDialogVisible = visible;
      if (!visible) {
        this.editRfId = null;
      }
      if (isRe) {
        this.getMainGoodsRfIdTableData();
      }
    },
    handleCloseGatewayNumber(visible,type){
      this.type = type;
      this.gatewayNumberDialogVisible = visible;
    },
    resetDevice(){
      this.resetLoading = true;
      this.$axios.post("/sys-api/device-api/reset",{deviceId:this.deviceId},(res) => {
        this.resetLoading = false;
        if(res.code === '100'){
          this.$message.success("重置成功");
          this.getDeviceBaseInfo();
        }
      })
    },
    upgradeApk(){
      this.upgradeLoading = true;
      this.$axios.post("/sys-api/apk-api/apkSpecific",{deviceId:this.deviceId},(res) => {
        this.upgradeLoading = false;
        if(res.code === '100'){
          this.$message.success("已通知设备升级apk");
          this.getDeviceBaseInfo();
        }
      })
    },
    changeDeviceStatus(){
      this.forbiddenLoading = true;
      if(this.baseForm.deviceStatus === 1){
        this.$axios.post("/sys-api/device-api/forbidden",{id:this.deviceId},(res) => {
          this.forbiddenLoading = false;
          if(res.code === '100'){
            this.$message.success("禁用成功");
            this.getDeviceBaseInfo();
          }
        })
        return
      }
      if(this.baseForm.deviceStatus === 0){
        this.$axios.post("/sys-api/device-api/enable",{id:this.deviceId},(res) => {
          this.forbiddenLoading = false;
          if(res.code === '100'){
            this.$message.success("启用成功");
            this.getDeviceBaseInfo();
          }
        })
        return
      }
    },
    goHeartRecord(){
      setMenuList({
        path: '/heartRecord',
        name: '心跳记录'
      })
      this.$router.push({name:'HeartRecord',query:{id:this.deviceId}})
    }
  },
  mounted() {
    //let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    //console.log(userInfo);
    let menuList = [
      {
        name: "租赁设备管理",
        path: "/device"
      },
      {
        name: "设备详情",
        path: "/deviceDetail",
        query:{id:this.deviceId}
      }
    ]
    resetMenuList(menuList)
    this.getDeviceBaseInfo();
  }
}
</script>

<style scoped lang="scss">
.el-tabs {
  padding:10px;
  height: calc(100% - 20px);
}

::v-deep .el-tabs__content {
  height: calc(100% - 56px - 15px);
  overflow: hidden;
}

.el-tab-pane {
  height: 100%;
}

.btn-container {
  position: absolute;
  top: 73px;
  right: 20px;
  z-index: 2;
}

.btn-list {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-btn-container.btn-list {
  justify-content: center;

  .submit-btn {
    padding: 12px 50px;
  }
}

.small-input {
  width: 300px;
}
.total-info{
  padding-top: 0px;
}

.table-container-main {
  height:calc(100% - 35px - 50px - 40px);
}

.table-container-error, .table-container-main-goods {
  height: 100%;
}

.table-container-other {
  height: calc(100% - 160px);
}

.value {
  display: inline-block;
  min-width: 60px;
}
</style>
